import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { CarData } from "../models";
import { fetchByPath, validateField } from "./utils";
import { DataStore } from "aws-amplify";

export default function CustomCarDataCreateForm(props) {
  const currDate = new Date();
  const currYear = currDate.getFullYear();
  
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    carName: "",
    make: "",
    model: "",
    licensePlate: "",
    year: "",
    yearStartMileage: "",
    yearEndMileage: "",
  };
  const [carName, setCarName] = React.useState(initialValues.carName);
  const [make, setMake] = React.useState(initialValues.make);
  const [model, setModel] = React.useState(initialValues.model);
  const [licensePlate, setLicensePlate] = React.useState(
    initialValues.licensePlate
  );
  const [year, setYear] = React.useState(initialValues.year);
  const [yearStartMileage, setYearStartMileage] = React.useState(
    initialValues.yearStartMileage
  );
  const [yearEndMileage, setYearEndMileage] = React.useState(
    initialValues.yearEndMileage
  );
  const [errors, setErrors] = React.useState({});

  const resetStateValues = () => {
    setCarName(initialValues.carName);
    setMake(initialValues.make);
    setModel(initialValues.model);
    setLicensePlate(initialValues.licensePlate);
    setYear(initialValues.year);
    setYearStartMileage(initialValues.yearStartMileage);
    setYearEndMileage(initialValues.yearEndMileage);
    setErrors({});
  };
  const validations = {
    carName: [{
      type: "LessThanChar",
      numValues: [20],
      validationMessage: "The car name must be 20 characters or fewer",
    },],
    make: [{ type: "Required" }, 
    {type: "LessThanChar",
    numValues: [16],
    validationMessage: "The car's make must be 16 characters or fewer",
  },
],
    model: [{ type: "Required" }, 
    {
      type: "LessThanChar",
      numValues: [16],
      validationMessage: "The car's model must be 16 characters or fewer",
    },
],
    licensePlate: [{ type: "Required" },
    {
      type: "GreaterThanChar",
      numValues: [5],
      validationMessage: "The license plate must have at least 6 characters",
    },
    {
      type: "LessThanChar",
      numValues: [8],
      validationMessage: "The license plate must have 8 or less characters",
    },
  ],
    year: [{ type: "Required" },
    {
      type: "GreaterThanNum",
      numValues: [1896],
      validationMessage: "The year must be greater than 1896",
    },
    {
      type: "LessThanNum",
      numValues: [currYear + 2],
      validationMessage: "The year must be less than 2025",
    },
  ],
    yearStartMileage: [],
    yearEndMileage: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          carName,
          make,
          model,
          licensePlate,
          year,
          yearStartMileage,
          yearEndMileage,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          await DataStore.save(new CarData(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "CustomCarDataCreateForm")}
      {...rest}
    >
      <TextField
        label="Car Nickname"
        isRequired={false}
        isReadOnly={false}
        value={carName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              carName: value,
              make,
              model,
              licensePlate,
              year,
              yearStartMileage,
              yearEndMileage,
              make,
              model,
            };
            const result = onChange(modelFields);
            value = result?.carName ?? value;
          }
          if (errors.carName?.hasError) {
            runValidationTasks("carName", value);
          }
          setCarName(value);
        }}
        onBlur={(e) => {
          let { value } = e.target
          runValidationTasks("carName", carName);
          //Alphanumeric check
          let strRegex = new RegExp(/^[\w\-\s]+$/);
          let result = strRegex.test(value);
          if (value && !result) {
            setErrors((errors) => ({ ...errors, ["carName"]: {hasError: true, errorMessage: "Must be alphanumeric, -, _, or space"} }))
          }
        }}
        errorMessage={errors.carName?.errorMessage}
        hasError={errors.carName?.hasError}
        {...getOverrideProps(overrides, "carName")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Make</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        value={make}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              carName,
              make: value,
              model,
              licensePlate,
              year,
              yearStartMileage,
              yearEndMileage,
            };
            const result = onChange(modelFields);
            value = result?.make ?? value;
          }
          if (errors.make?.hasError) {
            runValidationTasks("make", value);
          }
          setMake(value);
        }}
        onBlur={(e) => {
          let { value } = e.target
          runValidationTasks("make", make);
          //Alphanumeric check
          let strRegex = new RegExp(/^[a-z0-9]+$/i);
          let result = strRegex.test(value);
          console.log(result)
          if (!result) {
            setErrors((errors) => ({ ...errors, ["make"]: {hasError: true, errorMessage: "Must be alphanumeric, -, _, or space"} }))
          }
        }}
        errorMessage={errors.make?.errorMessage}
        hasError={errors.make?.hasError}
        {...getOverrideProps(overrides, "make")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Model</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        value={model}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              carName,
              make,
              model: value,
              licensePlate,
              year,
              yearStartMileage,
              yearEndMileage,
            };
            const result = onChange(modelFields);
            value = result?.model ?? value;
          }
          if (errors.model?.hasError) {
            runValidationTasks("model", value);
          }
          setModel(value);
        }}
        onBlur={(e) => {
          let { value } = e.target
          runValidationTasks("model", model);
          //Alphanumeric check
          let strRegex = new RegExp(/^[\w\-\s]+$/);
          let result = strRegex.test(value);
          console.log(result)
          if (!result) {
            setErrors((errors) => ({ ...errors, ["model"]: {hasError: true, errorMessage: "Must be alphanumeric, -, _, or space"} }))
          }
        }}
        errorMessage={errors.model?.errorMessage}
        hasError={errors.model?.hasError}
        {...getOverrideProps(overrides, "model")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>License Plate</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        value={licensePlate}
        onChange={(e) => {
          let { value } = e.target;
          value.toUpperCase();
          if (onChange) {
            const modelFields = {
              carName,
              make,
              model,
              licensePlate: value,
              year,
              yearStartMileage,
              yearEndMileage,
            };
            const result = onChange(modelFields);
            value = result?.licensePlate ?? value;
          }
          if (errors.licensePlate?.hasError) {
            runValidationTasks("licensePlate", value);
          }
          setLicensePlate(value.toUpperCase());
        }}
        onBlur={(e) => {
          let { value } = e.target
          runValidationTasks("licensePlate", licensePlate);
          //Alphanumeric check
          let strRegex = new RegExp(/^[\w\-\s]+$/);
          let result = strRegex.test(value);
          console.log(result)
          if (!result) {
            setErrors((errors) => ({ ...errors, ["licensePlate"]: {hasError: true, errorMessage: "Must be alphanumeric"} }))
          }
        }}
        errorMessage={errors.licensePlate?.errorMessage}
        hasError={errors.licensePlate?.hasError}
        {...getOverrideProps(overrides, "licensePlate")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Year</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={year}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              carName,
              make,
              model,
              licensePlate,
              year: value,
              yearStartMileage,
              yearEndMileage,
            };
            const result = onChange(modelFields);
            value = result?.year ?? value;
          }
          if (errors.year?.hasError) {
            runValidationTasks("year", value);
          }
          setYear(value);
        }}
        onBlur={() => runValidationTasks("year", year)}
        errorMessage={errors.year?.errorMessage}
        hasError={errors.year?.hasError}
        {...getOverrideProps(overrides, "year")}
      ></TextField>
      <TextField
        label="Current Year Starting Mileage"
        isRequired={false}
        isReadOnly={false}
        type="number"
        min="0"
        step="any"
        value={yearStartMileage}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              carName,
              make,
              model,
              licensePlate,
              year,
              yearStartMileage: value,
              yearEndMileage,
            };
            const result = onChange(modelFields);
            value = result?.yearStartMileage ?? value;
          }
          if (errors.yearStartMileage?.hasError) {
            runValidationTasks("yearStartMileage", value);
          }
          setYearStartMileage(value);
        }}
        onBlur={() => {
          if (yearStartMileage < 0) {
            setErrors((errors) => ({...errors, ["yearStartMileage"]: {hasError: true, errorMessage: "Year start mileage must be greater than 0"}}));
          }
          else {
            runValidationTasks("yearStartMileage", yearStartMileage);
          }
        }}
        errorMessage={errors.yearStartMileage?.errorMessage}
        hasError={errors.yearStartMileage?.hasError}
        {...getOverrideProps(overrides, "yearStartMileage")}
      ></TextField>
      <TextField
        label="Current Year Ending Mileage"
        isRequired={false}
        isReadOnly={false}
        type="number"
        min="0"
        step="any"
        value={yearEndMileage}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              carName,
              make,
              model,
              licensePlate,
              year,
              yearStartMileage,
              yearEndMileage: value,
            };
            const result = onChange(modelFields);
            value = result?.yearEndMileage ?? value;
          }
          if (errors.yearEndMileage?.hasError) {
            runValidationTasks("yearEndMileage", value);
          }
          setYearEndMileage(value);
        }}
        onBlur={() => { 
          if (yearStartMileage && yearStartMileage > yearEndMileage) {
            setErrors((errors) => ({ ...errors, ["yearEndMileage"]: {hasError: true, errorMessage: "Year end mileage must be greater than year start mileage"} }));
          }
          else if (yearEndMileage < 0) {
            setErrors((errors) => ({...errors, ["yearEndMileage"]: {hasError: true, errorMessage: "Year end mileage must be greater than 0"}}));
          }
          else {
            runValidationTasks("yearEndMileage", yearEndMileage);
          }
        }}
        errorMessage={errors.yearEndMileage?.errorMessage}
        hasError={errors.yearEndMileage?.hasError}
        {...getOverrideProps(overrides, "yearEndMileage")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
