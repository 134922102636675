import React, { useState } from 'react';
import { Flex } from '@aws-amplify/ui-react';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { useDataStoreBinding } from "@aws-amplify/ui-react/internal";

import UserCarTotalsEntryCollection from '../../components/UserCarTotalsEntryCollection';
import Header from '../../components/Header';
import { TotalsHeader } from '../../ui-components';
import CustomTotalsTableEntryCollection from '../../components/CustomTotalsTableEntryCollection';
import { Mileage } from '../../models';
import { checkUserTable } from '../../functions/UserTablePopulate';

function MyTotals({ signOut, user}) {
  const [monthFilter, setMonthFilter] = useState(new Date());
  const [currentYearMileage, setCurrentYearMileage] = React.useState();
  const [filteredYearMileage, setFilteredYearMileage] = React.useState();
  const navigate = useNavigate();
  const mileageDataStore = useDataStoreBinding({
    type: "collection",
    model: Mileage,
  }).items;
  React.useEffect(()=>{
    let admin = false;
    if(user.signInUserSession.accessToken.payload["cognito:groups"]){
      if (user.signInUserSession.accessToken.payload["cognito:groups"].includes("Admin")){
        admin = true;
      }
    }
    if(admin){
      navigate("/AdminView");
    }
  }, [])
  React.useEffect(() => {
    let year = new Date().getFullYear();
    let filterYear = monthFilter.getFullYear();
    setCurrentYearMileage(mileageDataStore.filter(mileage => mileage.year === year)[0])
    setFilteredYearMileage(mileageDataStore.filter(mileage => mileage.year === filterYear)[0])
  }, [mileageDataStore, monthFilter])

  checkUserTable(user);
  return (
    <div className='App' style={({display: "flex"})} width="100%">
      <div className='content' style={({flexGrow:100})}>
      <Header user={user} signOut={signOut}/>
      <Flex className="amplify-flex" direction="row" style={{marginLeft: "1em"}}>
        <div directiion="column" alignItems="flex-start">
          {/* <p>{"Current Year ("}{new Date().getFullYear()}{") Mileage: "}{currentYearMileage?.rate}</p> */}
          <p>{"Filtered Year ("}{monthFilter.getFullYear()}{") Mileage: "}{filteredYearMileage?.rate}</p>
        </div>
      </Flex>
      <div style={{display:"flex", width: "fit-content", marginLeft: "1vw", justifyContent: "flex-start"}}> 
        <div style={{fontWeight: "bold", marginRight: "1vw"}}>Month:</div>
        <DatePicker
        selected={monthFilter}
        onChange={(date) => setMonthFilter(date)}
        dateFormat="MM/yyyy"
        showMonthYearPicker
      />
      </div>

      <TotalsHeader/>
      <CustomTotalsTableEntryCollection monthFilter={monthFilter}/>
      <Flex className="amplify-flex" direction="column" alignItems="flex-start">
        <UserCarTotalsEntryCollection userID={user.attributes.sub} monthFilter={monthFilter}></UserCarTotalsEntryCollection>
      </Flex>
      </div>
    </div>
  )
}

export default MyTotals;
