import { DataStore } from "aws-amplify";
import React from "react";
import { CarData, ChargeCodes, CommuteEntries, Mileage } from "../models";
import CustomCommuteEntriesUpdateForm from "./CustomCommuteEntriesUpdateForm"

async function getCommute(commuteid) {
    const commute = await DataStore.query(CommuteEntries, commuteid);
    console.log(commuteid)
    return commute;
}

async function getCars() {
    console.log("Querying Cars")
    const userCars = await DataStore.query(CarData);
    return userCars;
}

async function getMileage() {
    console.log("Querying Mileage")
    const mileage = await DataStore.query(Mileage);
    return mileage;
  }

async function getAddresses() {
    console.log("Querying Commutes")
    const userCommutes = await DataStore.query(CommuteEntries);
    let addresses = userCommutes.map(commute=>commute.endAddress);
    addresses = [...addresses, ...userCommutes.map(commute=>commute.startAddress)];
    const uniqueAddresses = addresses.filter((val, id, array) => {
      return array.indexOf(val) === id;
    });
    const addressOptions = uniqueAddresses.map((address) => {
      return {label: address};
    })
    return addressOptions;
  }

export class UpdateCommuteForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            commute: {},
            carList: [],
            mileage: [],
            addresses: [],
            commuteLoaded: false
        }
        getCars().then((response) => { 
            this.setState({
              carList: response.filter(car => car.usersID.includes(this.props.userID))
            });
        });
        getMileage().then((response) => {
            this.setState({mileage: response});
          });
        getAddresses().then((response) => {
            this.setState({addresses: response});
        });
    }

    componentDidMount() {
        getCommute(this.props.commuteid).then((response) => {
            this.setState({
                commute: response,
                commuteLoaded: true
            })
            console.log(response)
        })
    }

    render() {
        if(this.state.commuteLoaded){
            return (
              
              <CustomCommuteEntriesUpdateForm 
                  commuteEntries={this.state.commute}
                  overrides={{
                      CarData: {options: this.state.carList.map(car => car.carName? car.carName: (car.make + ' ' + car.model))},
                      startAddress: {options: this.state.addresses},
                      endAddress: {options: this.state.addresses}
                  }}
                  onValidate={{
                      odometerStart: (value, validationResponse) => {
                        if (value < 0) {
                          return {
                            hasError: true,
                            errorMessage: "The value must be greater than 0"
                          }
                        }
                        return validationResponse;
                      },
                      odometerEnd: (value, validationResponse) => {
                        if (value < 0) {
                          return {
                            hasError: true,
                            errorMessage: "The value must be greater than 0"
                          }
                        }
                        return validationResponse;
                      },
                      parking: (value, validationResponse) => {
                        if (value < 0) {
                          return {
                            hasError: true,
                            errorMessage: "The cost of parking must not be negative"
                          }
                        }
                        return validationResponse
                      },
                      tolls: (value, validationResponse) => {
                        if (value < 0) {
                          return {
                            hasError: true,
                            errorMessage: "The cost of parking must not be negative"
                          }
                        }
                        return validationResponse
                      }
                  }}
                  onSubmit= {(fields) => {
                      console.log("Started Submitting")
          
                      // calculating total cost
                      const year = parseInt(fields.date.slice(0,4));
                      const currMileage = this.state.mileage.filter(mileage => mileage.year === year)[0];
                      const mileageRate = currMileage.rate;
                      if(fields.parking === ""){
                        console.log("parking")
                        fields.parking = 0;
                      }
                      if(fields.tolls === ""){
                        console.log("tolls")
                        fields.tolls = 0;
                      }
                      fields.total = Math.ceil((mileageRate * fields.miles + fields.parking + fields.tolls) * 100) / 100;
      
                      const selectedCar = this.state.carList.filter(car => (car.carName? car.carName: (car.make + ' ' + car.model)) === fields.CarData)[0];
                      let charge = "";
                      if (fields.chargeCode == ChargeCodes.OVERHEAD) {
                        charge = "Overhead";
                      } else if (fields.chargeCode == ChargeCodes.UNALLOWABLE) {
                        charge = "Unallowable";
                      } else if (fields.chargeCode == ChargeCodes.GA) {
                        charge = "G&A";
                      }
                      const updatedFields = Object.assign(fields, {cardataID: selectedCar.id, usersID: this.props.userID, carNameString: selectedCar.carName, chargeCodeString: charge});
                      return updatedFields;
                    }}
                    onSuccess= {() => {
                      console.log("Successful upload")
                      if (this.props.closeDialog){
                        this.props.closeDialog();
                      } else {
                        this.props.navHook("/PastEntries");
                      }
                    }}
                    onError= {(error,msg) => {
                      console.log("error")
                      console.log(error)
                      console.log(msg)
                    }}>
              </CustomCommuteEntriesUpdateForm>
                
            )
        } else {
            return (
                <div>Loading...</div>
            )
        }
        
    }
}