/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getExpenseAdminUsage = /* GraphQL */ `
  query GetExpenseAdminUsage($id: ID!) {
    getExpenseAdminUsage(id: $id) {
      id
      EmployeeName
      EmployeeNumber
      PeriodStart
      PeriodEnd
      EmployeeSignature
      EmployeeDate
      ApproverName
      ApproverNumber
      ApproverSignature
      ApproverDate
      Total
      BillableTotal
      ExpenseReportNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listExpenseAdminUsages = /* GraphQL */ `
  query ListExpenseAdminUsages(
    $filter: ModelExpenseAdminUsageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenseAdminUsages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        EmployeeName
        EmployeeNumber
        PeriodStart
        PeriodEnd
        EmployeeSignature
        EmployeeDate
        ApproverName
        ApproverNumber
        ApproverSignature
        ApproverDate
        Total
        BillableTotal
        ExpenseReportNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncExpenseAdminUsages = /* GraphQL */ `
  query SyncExpenseAdminUsages(
    $filter: ModelExpenseAdminUsageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExpenseAdminUsages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        EmployeeName
        EmployeeNumber
        PeriodStart
        PeriodEnd
        EmployeeSignature
        EmployeeDate
        ApproverName
        ApproverNumber
        ApproverSignature
        ApproverDate
        Total
        BillableTotal
        ExpenseReportNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getExpense = /* GraphQL */ `
  query GetExpense($id: ID!) {
    getExpense(id: $id) {
      id
      Date
      Item
      Comments
      Cost
      Mileage
      Total
      Billable
      BillableTotal
      usersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listExpenses = /* GraphQL */ `
  query ListExpenses(
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Date
        Item
        Comments
        Cost
        Mileage
        Total
        Billable
        BillableTotal
        usersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncExpenses = /* GraphQL */ `
  query SyncExpenses(
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Date
        Item
        Comments
        Cost
        Mileage
        Total
        Billable
        BillableTotal
        usersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const expensesByUsersID = /* GraphQL */ `
  query ExpensesByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    expensesByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Date
        Item
        Comments
        Cost
        Mileage
        Total
        Billable
        BillableTotal
        usersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMileage = /* GraphQL */ `
  query GetMileage($id: ID!) {
    getMileage(id: $id) {
      id
      year
      rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMileages = /* GraphQL */ `
  query ListMileages(
    $filter: ModelMileageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMileages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMileages = /* GraphQL */ `
  query SyncMileages(
    $filter: ModelMileageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMileages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        year
        rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      first_name
      last_name
      email
      CommuteEntries {
        items {
          id
          date
          chargeCode
          startAddress
          endAddress
          odometerStart
          odometerEnd
          miles
          parking
          tolls
          total
          purpose
          carNameString
          chargeCodeString
          cardataID
          usersID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      CarData {
        items {
          id
          carName
          licensePlate
          year
          yearStartMileage
          yearEndMileage
          usersID
          make
          model
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Expenses {
        items {
          id
          Date
          Item
          Comments
          Cost
          Mileage
          Total
          Billable
          BillableTotal
          usersID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        email
        CommuteEntries {
          nextToken
          startedAt
          __typename
        }
        CarData {
          nextToken
          startedAt
          __typename
        }
        Expenses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        first_name
        last_name
        email
        CommuteEntries {
          nextToken
          startedAt
          __typename
        }
        CarData {
          nextToken
          startedAt
          __typename
        }
        Expenses {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCarData = /* GraphQL */ `
  query GetCarData($id: ID!) {
    getCarData(id: $id) {
      id
      carName
      licensePlate
      year
      yearStartMileage
      yearEndMileage
      CommuteEntries {
        items {
          id
          date
          chargeCode
          startAddress
          endAddress
          odometerStart
          odometerEnd
          miles
          parking
          tolls
          total
          purpose
          carNameString
          chargeCodeString
          cardataID
          usersID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      usersID
      make
      model
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listCarData = /* GraphQL */ `
  query ListCarData(
    $filter: ModelCarDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        carName
        licensePlate
        year
        yearStartMileage
        yearEndMileage
        _deleted
        CommuteEntries {
          nextToken
          startedAt
          __typename
        }
        usersID
        make
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCarData = /* GraphQL */ `
  query SyncCarData(
    $filter: ModelCarDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        carName
        licensePlate
        year
        yearStartMileage
        yearEndMileage
        CommuteEntries {
          nextToken
          startedAt
          __typename
        }
        usersID
        make
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const carDataByUsersID = /* GraphQL */ `
  query CarDataByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCarDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carDataByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carName
        licensePlate
        year
        yearStartMileage
        yearEndMileage
        CommuteEntries {
          nextToken
          startedAt
          __typename
        }
        usersID
        make
        model
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCommuteEntries = /* GraphQL */ `
  query GetCommuteEntries($id: ID!) {
    getCommuteEntries(id: $id) {
      id
      date
      chargeCode
      startAddress
      endAddress
      odometerStart
      odometerEnd
      miles
      parking
      tolls
      total
      purpose
      carNameString
      chargeCodeString
      cardataID
      usersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listCommuteEntries = /* GraphQL */ `
  query ListCommuteEntries(
    $filter: ModelCommuteEntriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommuteEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        chargeCode
        startAddress
        endAddress
        odometerStart
        odometerEnd
        miles
        parking
        tolls
        total
        purpose
        carNameString
        chargeCodeString
        cardataID
        usersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCommuteEntries = /* GraphQL */ `
  query SyncCommuteEntries(
    $filter: ModelCommuteEntriesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCommuteEntries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        chargeCode
        startAddress
        endAddress
        odometerStart
        odometerEnd
        miles
        parking
        tolls
        total
        purpose
        carNameString
        chargeCodeString
        cardataID
        usersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commuteEntriesByCardataID = /* GraphQL */ `
  query CommuteEntriesByCardataID(
    $cardataID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommuteEntriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commuteEntriesByCardataID(
      cardataID: $cardataID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        chargeCode
        startAddress
        endAddress
        odometerStart
        odometerEnd
        miles
        parking
        tolls
        total
        purpose
        carNameString
        chargeCodeString
        cardataID
        usersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commuteEntriesByUsersID = /* GraphQL */ `
  query CommuteEntriesByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommuteEntriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commuteEntriesByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        chargeCode
        startAddress
        endAddress
        odometerStart
        odometerEnd
        miles
        parking
        tolls
        total
        purpose
        carNameString
        chargeCodeString
        cardataID
        usersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
