import * as React from "react";
import { Users } from "../models";
import { SortDirection } from "@aws-amplify/datastore";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import { Collection } from "@aws-amplify/ui-react";
import { config, Credentials, CognitoIdentityServiceProvider } from "aws-sdk";
import awsmobile from "../aws-exports";
import { Auth } from "aws-amplify";
import CustomEmployeeEntry from "./CustomEmployeeEntry";

var cognitoUsers = []
Auth.currentCredentials().then(res => {
    const creds = Auth.essentialCredentials(res)
    config.update({
        region: awsmobile.aws_project_region,
        credentials: new Credentials(creds.accessKeyId, creds.secretAccessKey, creds.sessionToken)
    });
    var cognitoidentityserviceprovider = new CognitoIdentityServiceProvider();
    var params = {
        AttributesToGet:["sub"],
        UserPoolId: awsmobile.aws_user_pools_id
    }

    cognitoidentityserviceprovider.listUsers(params, function (err, data) {
        if (err){
            console.log(err, err.stack);
        } else {
            cognitoUsers = data.Users;
            console.log(data)
        }
    })
})

export default function EmployeeEntryCollection(props) {
    const { overrideItems, overrides, ...rest } = props;
    const userPagination = { sort: (s) => s.last_name(SortDirection.ASCENDING) };
    const usersDataStore = useDataStoreBinding({
        type: "collection",
        model: Users,
        pagination: userPagination,
    }).items;

    const [users, setUsers] = React.useState([])

    var usersDatastoreTrimmed = []
    for (let i = 0; i < usersDataStore?.length; i++) {
        const user = usersDataStore[i];
        usersDatastoreTrimmed.push({id: user.id, first_name: user.first_name, last_name: user.last_name})
    }
    React.useEffect(() => {
        var finalUsers = [];
        for (let i = 0; i < usersDatastoreTrimmed.length; i++){
            const user = usersDatastoreTrimmed[i]
            var username = "Not Found"
            if (cognitoUsers) {
                for (let j = 0; j < cognitoUsers.length; j++) {
                    if (cognitoUsers[j].Attributes[0].Value === user.id){
                        username = cognitoUsers[j].Username
                    }
                }
                if (username != "Not Found")
                    finalUsers.push({id: user.id, first_name: user.first_name, last_name: user.last_name, username: username})
            }
        }
        if (finalUsers && finalUsers.length !== users.length)
            setUsers(finalUsers);
    })
    
    return (
        <div display="flex" style={({flexDirection:"column", alignItems:"flex-start", width:"100%"})}>
            <h2>User Account Management</h2>
            <Collection
            type="list"
            isSearchable={true}
            isPaginated={true}
            searchPlaceholder="Search Users..."
            itemsPerPage={10}
            direction="column"
            alignItems="stretch"
            justifyContent="left"
            items={users || []}
            {...rest}
            {...getOverrideProps(overrides, "TableEntryCollection")}
            >
            {(item, index) => (
                <CustomEmployeeEntry
                users={item}
                key={item.id}
                {...(overrideItems && overrideItems({ item, index }))}
                ></CustomEmployeeEntry>
            )}
            </Collection>
        </div>
    )
}