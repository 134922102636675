import React, { useEffect, useState, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Collection } from "@aws-amplify/ui-react";
import {
  listCarData,
  commuteEntriesByCardataID,
} from "../graphql/queries";

import UserCarTotalsEntry from "./UserCarTotalsEntry";
import UserTotalsHeader from "./UserTotalsHeader";

export default function UserCarTotalsEntryCollection({
  today = new Date(),
  userID,
  monthFilter = new Date(),
  selectedFilterUser,
  showInactive,
  overrideItems,
  overrides,
  ...rest
}) {
  const [userCarDataStore, setUserCarDataStore] = useState([]);
  const [userCommutesDataStore, setUserCommutesDataStore] = useState([]);
  const [lastMonth, setLastMonth] = useState();
  const [error, setError] = useState(null);

  console.log("showInactive is: ", showInactive);

  // Calculate last month's date
  useEffect(() => {
    const lastMonthDate = new Date(monthFilter);
    lastMonthDate.setDate(1);
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
    setLastMonth(lastMonthDate);
  }, [monthFilter]);

  const fetchData = useCallback(async () => {
    try {
      console.log("Fetching data...");

      // Fetch car data
      const carDataResult = await API.graphql(
        graphqlOperation(listCarData, {
          filter: selectedFilterUser
            ? { usersID: { eq: selectedFilterUser } }
            : {}, // If `selectedFilterUser` is not provided, fetch all cars
        })
      );
      let fetchedCars = carDataResult.data.listCarData.items;

      // console.log("Fetched Car Data (Before Sorting):", fetchedCars);

      if (!showInactive) {
        // When toggle is OFF (showInactive = false), exclude deleted cars
        fetchedCars = fetchedCars.filter((car) => !car._deleted);
      }

      // Alphabetize cars by car name, moving unnamed cars to the end
      fetchedCars = fetchedCars.sort((a, b) => {
        const nameA = a.carName?.toLowerCase() || null; // Null for undefined car names
        const nameB = b.carName?.toLowerCase() || null;

        // If one car name is null, move it to the end
        if (nameA === null && nameB !== null) return 1;
        if (nameA !== null && nameB === null) return -1;

        // Otherwise, sort alphabetically
        if (nameA !== null && nameB !== null) return nameA.localeCompare(nameB);

        // If both names are null, keep their original order
        return 0;
      });

      // console.log("Fetched Car Data (After Sorting):", fetchedCars);

      // Fetch commute data for all cars
      const commuteEntriesPromises = fetchedCars.map(async (car) => {
        const commuteEntriesResult = await API.graphql(
          graphqlOperation(commuteEntriesByCardataID, {
            cardataID: car.id,
          })
        );
        return commuteEntriesResult.data.commuteEntriesByCardataID.items;
      });

      const fetchedCommutes = (
        await Promise.all(commuteEntriesPromises)
      ).flat();
      // console.log("Fetched Commute Data:", fetchedCommutes);

      // Set fetched data into state
      setUserCarDataStore(fetchedCars); // Updated: Now reflects toggle state
      setUserCommutesDataStore(fetchedCommutes);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Error fetching car or commute data: " + err.message);
    }
  }, [selectedFilterUser, showInactive]); // Include `showInactive` as a dependency

  // Trigger data fetch on component mount or filter changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredCarData = userCarDataStore.filter((item) => {
    const isDeleted = item._deleted ?? false;

    return (
      (!selectedFilterUser || item.usersID.includes(selectedFilterUser)) &&
      (showInactive || !isDeleted) // Show all when showInactive is true, otherwise exclude deleted
    );
  });

  // console.log("Filtered Car Data:", filteredCarData);

  // Helper to filter commute entries by month
  const filterCommutesByMonth = (commutes, carId, date) =>
    commutes.filter(
      (item) =>
        item.cardataID === carId &&
        new Date(item.date).getFullYear() === date.getFullYear() &&
        new Date(item.date).getUTCMonth() === date.getUTCMonth()
    );

  // Calculate totals for each car
  const carTotals = filteredCarData.map((car) => {
    const currentMonthCommutes = filterCommutesByMonth(
      userCommutesDataStore,
      car.id,
      monthFilter
    );
    const totalMiles = currentMonthCommutes.reduce(
      (acc, commute) => acc + commute.miles,
      0
    );

    const lastMonthCommutes = filterCommutesByMonth(
      userCommutesDataStore,
      car.id,
      lastMonth
    );
    const lastMonthMiles = lastMonthCommutes.reduce(
      (acc, commute) => acc + commute.miles,
      0
    );

    return {
      id: car.id,
      name: car.carName,
      monthlyTotal: totalMiles,
      prevMonthTotal: lastMonthMiles,
      isDeleted: car._deleted, // Add this for additional styling if needed
    };
  });

  return (
    <div
      style={{
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      {error && <p style={{ color: "red" }}>{error}</p>}
      <UserTotalsHeader
        today={today}
        lastMonth={lastMonth}
        showInactive={showInactive}
      />
      <Collection
        marginTop={9}
        type="list"
        isPaginated={true}
        itemsPerPage={10}
        searchPlaceholder="Search..."
        direction="column"
        alignItems="stretch"
        justifyContent="left"
        items={carTotals || []}
        {...rest}
        {...overrides}
      >
        {(item, index) => (
          <UserCarTotalsEntry
            carTotals={item}
            key={item.id}
            {...(overrideItems && overrideItems({ item, index }))}
          />
        )}
      </Collection>
    </div>
  );
}
